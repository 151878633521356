<style scoped>
  header{
    background-color: #ffffff;
    font-family: "Montserrat"
  }
  .contact{
    background-color: #F8FAFE;
    border-radius: 5px;
    align-items: center;
  }
  .contact a{
    outline: none;
    text-decoration: none;
    color: #000000;
  }
  .text-wsp{
    color: #8695A0;
    font-size: 16px;
    font-weight: 400;
  }
  .container{
    display: flex;
    align-items: center;
  }
</style>

<template>
  <header class="container row justify-content-between p-3 mt-3 m-auto">
    <div class="col-12 col-sm-7 mb-3">
      <div class="d-flex justify-content-center justify-content-md-start w-100">
        <img src="../../../assets/img/logo.png" alt="Logo" class="w-75">
      </div>
    </div>
    <div class="contact col-12 col-sm-5 fs-4 pt-3">
      <a href="tel:+2365159731"><p class="mb-2"><font-awesome-icon icon="phone" class="me-2 blue" />Contact: <strong>236 515 9731</strong></p></a>
      <p><font-awesome-icon icon="location-dot" class="me-2 blue" />Queensbotough, New Westminster</p>
    </div>
  </header>
</template>
