<style scoped>
  .copyright {
    background-color: #000000;
    color: #ffffff;
    padding: 1rem 0;
    font-size: 16px;
    font-weight: 500;
  }
  .img-map{
    box-shadow: -0.25rem -0.25rem 1rem rgba(0, 0, 0, 0.25);
  }
</style>

<template>
  <footer id="footer" class="footer mx-0 mb-0 pb-0">
    <section>
      <div class="row justify-content-end">
        <div class="col-md-6 col-lg-4">
          <div class="card">
            <div class="card-body row justify-content-between">
              <div class="col-1">
                <font-awesome-icon icon="phone" size="2x" />
              </div>
              <div class="col-10">
                <h5>Book an appointment</h5>
                <div class="row">
                  <div class="col-5 col-md-3 blue fs-6 fw-bold">Sales:</div>
                  <div class="col-7 col-md-9 fw-bolder fs-4"><a href="tel:+2365159731">(236) 515-9731</a></div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body row justify-content-between">
              <div class="col-1">
                <font-awesome-icon icon="calendar-days" size="2x" />
              </div>
              <div class="col-10">
                <h5>Hours of operation</h5>
                <div class="row">
                  <div class="col-5 blue fs-6 fw-bold">Mon - Thu</div>
                  <div class="col-7">9:00 AM - 9:00 PM</div>
                </div>
                <div class="row">
                  <div class="col-5 blue fs-6 fw-bold">Fri</div>
                  <div class="col-7">9:00 AM - 7:00 PM</div>
                </div>
                <div class="row">
                  <div class="col-5 blue fs-6 fw-bold">Sat</div>
                  <div class="col-7">9:00 AM - 6:00 PM</div>
                </div>
                <div class="row">
                  <div class="col-5 blue fs-6 fw-bold">Sun</div>
                  <div class="col-7">9:00 AM - 5:00 PM</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-6">
          <a href="https://maps.app.goo.gl/1oHwSJM9nYiJxge98" target="_blank">
            <img class="img-map w-100" src="../../../assets/img/map.jpg" alt="">
          </a>
        </div>
      </div>
    </section>
    <div class="copyright montserrat text-center">
      &copy; {{currentYear}} Car Sales BC. All rights reserved.
    </div>
  </footer>
</template>

<script>
export default {
  computed: {
    currentYear () {
      return new Date().getFullYear()
    }
  }
}
</script>
