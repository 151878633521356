import { createRouter, createWebHistory } from 'vue-router'
import landingLayout from '../LandingLayout.vue'
import adminLayout from '../AdminLayout.vue'
// import { isAuthenticatedGuard, hasPermissionGuard} from './guards/authAdminGuard'

const routes = [
  {
    path: '/',
    component: landingLayout,
    children: [
      { path: '', component: () => import('../views/landing/landing-page.vue'), name: 'home' },
      { path: '/admin/login', component: () => import('../views/admin/login-page.vue'), name: 'login' }
    ]
  },
  {
    path: '/admin',
    component: adminLayout,
    meta: { requiresAuth: true },
    children: [
      { path: '', redirect: { name: 'vehiculos' } },
      { path: '/admin/vehiculos', component: () => import('../views/admin/vehiculos/vehiculos-page.vue'), name: 'vehiculos' }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// routes.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     if (!isAuthenticatedGuard()) {
//       next({ path: '/admin/login' });
//     } else {
//       next();
//     }
//   } else {
//     next();
//   }
// });

export default router
