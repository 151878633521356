<template>
    <aside id="sidebar" class="sidebar">

        <ul class="sidebar-nav" id="sidebar-nav">

            <li class="nav-item">
                <a class="nav-link" href="" :class="{'active': $route.name == 'vehiculos'}">
                    <font-awesome-icon icon="car" class="me-3" />
                </a>
            </li>

            <!-- <li class="nav-item">
                <a class="nav-link" href="" :class="{'active': $route.name == 'usuarios'}">
                    <font-awesome-icon icon="users" class="me-3" />
                    <span>Usuarios</span>
                </a>
            </li> -->

        </ul>

    </aside>
</template>
